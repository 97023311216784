import React from 'react';
import { Hinge } from 'react-awesome-reveal';
import { FaWhatsapp } from 'react-icons/fa';

const WhatsAppButton = ({style}) => {
// Replace number.................... boy
  const phoneNumber = '+2348129400909';
  const message = ' Thank you for contacting NNAROD GLOBAL CONCEPT, Please let us know how we can help you.';

  const handleClick = () => {
    const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  return (
    <button onClick={handleClick} className={style}>
     <Hinge duration={4000}>
     <FaWhatsapp className='text-[#46d646] text-[40px]' />
     </Hinge>
    </button>
  );
};

export default WhatsAppButton;
