import React from 'react'
import Navbar from '../layout/Navbar'
import Footer from '../layout/Footer'
import { Fade, Slide } from 'react-awesome-reveal'
import PageHeader from '../components/PageHeader'
import { Link } from 'react-router-dom'

const Service = () => {
    const cardData = [
        {
            id: 1,
            title: 'CONSULTANCY',
            content: 'We offer professional consultancy services for the most important interior design trends: Control room for home/ office networking Speakers (distributed audio system) Motorized window dressing for privacy Programmed lightning controls.'
        },
        {
            id: 2,
            title: 'SPACE PLANNING',
            content: 'This service allows us to complement your project by marrying the exterior of your property with the design of the interior. We will work with you and your architect to create a floor plan for your internal structure.'
        },
        {
            id: 3,
            title: 'PROCUREMENT',
            content: 'Nnroid Interiors’ team has a relationship with the best in the industry. This means we are able to source the best solutions, finishing, contractors and the qualified people for the project. We will make sure you get the best out of your budget without compromising quality, and ensure the perfect marriage of design and implementation.'
        },
        {
            id: 4,
            title: 'CLOSE OUT',
            content: 'We close out your project by handing over all relevant and agreed concepts according to what has been signed on and approved..'
        },
        {
            id: 5,
            title: 'CONCEPTUAL DESIGN AND CREATIVE DIRECTION',
            content: 'We will review your space and create the perfect creative design for you. From space planning, to interior design and architecture, we will conceptualise everything you need to make your project a reality.'
        },
        {
            id: 6,
            title: 'SITE SUPERVISION',
            content: 'We at Nnarod Interiors know that no project can be completed without good supervision. We can’t expect the end result to be perfect if we don’t monitor it ourselves. That is why members of our team will make periodic site visits to assess the progress of the project.'
        },
    ]
    return (
        <div>
            <Navbar />
            <Fade duration={3000} triggerOnce>
                <PageHeader message={"SERVICES"} />
            </Fade>

            <main className='max-w-[1600px] mx-auto'>
                <Slide triggerOnce duration={'2000'}  direction="left">
                    <h1 className='md:text-[30px] text-[22px] text-center py-12 font-extrabold font-regular'>OUR SERVICES</h1>
                </Slide>

                <div className="lgl:px-20 md:px-12 px-5 grid lg:grid-cols-3 md:grid-cols-2 pb-10    place-items-center  grid-cols-1 gap-5 ">
                   <Fade triggerOnce duration={3000}>
                   {cardData.map((item) => (
                        <div key={item.id} className={`max-w-sm min-h-[25rem]  py-16 shadow-md   px-6 border rounded-sm ${item.id % 2 === 0 ? 'bg-slate-100' : ''}`}>
                            <h1 className='text-center text-[16px] font-semibold font-regular'>{item.title}</h1>
                            <p className='mt-3 text-[14px] text-slate-500 font-regular leading-7 text-center'>{item.content}</p>
                            <div className="mt-8 flex justify-center">
                                <button className='border font-regular text-[15px] hover:bg-[#E21515] hover:text-white  px-5 py-2 text-[#E21515] font-semibold rounded-sm border-[#E21515]'>
                                    <Link to='/Contact-us'>Contact us</Link>
                                </button>
                            </div>
                        </div>
                    ))}
                    </Fade>
                </div>


            </main>
            <Footer />
        </div>
    )
}

export default Service