import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
// import HomePage from './screens/Home'
import Loadable from "react-loadable";
import Preloader from './components/loader/Preloader'
import WhatsAppButton from './components/WhatsAppButton';
import { ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Service from './screens/Service';
import Bed from './screens/Bed';

const HomePage = Loadable({
  loader: () => import("./screens/Home"),
  loading: Preloader,
});

const AboutPage = Loadable({
  loader: () => import("./screens/About"),
  loading: Preloader,
});
const ContactUs = Loadable({
  loader: () => import("./screens/ContactUs"),
  loading: Preloader,
});
const CurtainsPage = Loadable({
  loader: () => import("./screens/Curtains"),
  loading: Preloader,
});
const WindowsPage = Loadable({
  loader: () => import("./screens/WindowBlinds"),
  loading: Preloader,
});
const AccessoriesPage = Loadable({
  loader: () => import("./screens/Accessories"),
  loading: Preloader,
});
const ErrorPage = Loadable({
  loader: () => import("./screens/Error"),
  loading: Preloader,
});

const App = () => {
  return (
    <div>
      <Router>
        <Routes>
        <Route path="/" element={<HomePage />} exact />
        <Route path="/About-us" element={<AboutPage />}  />
        <Route path="/Contact-us" element={<ContactUs />}  />
        <Route path="/Services" element={<Service />} />
        <Route path="/Curtains" element={<CurtainsPage />}  />
        <Route path="/Accessories" element={<AccessoriesPage />}  />
        <Route path="/Windows" element={<WindowsPage />}  />
        <Route path="/Bed-sheets" element={<Bed />} />
        <Route path="*" element={<ErrorPage />}  />
        </Routes>
      </Router>
      <ToastContainer />
    </div>
  );
};

export default App;
