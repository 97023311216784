import img1 from './assests/BedSheets/image_1.jpeg'
import img2 from './assests/BedSheets/image_2.jpeg'
import img3 from './assests/BedSheets/image_3.jpeg'
import img4 from './assests/BedSheets/image_4.jpeg'
import img5 from './assests/BedSheets/image_5.jpeg'
import img6 from './assests/BedSheets/image_6.jpeg'
import img7 from './assests/BedSheets/image_7.jpeg'
// import img8 from './assests/BedSheets/image_8.jpeg'
import img9 from './assests/BedSheets/image_9.jpeg'
import img10 from './assests/BedSheets/image_10.jpeg'
import img11 from './assests/BedSheets/image_11.jpeg'
import img12 from './assests/BedSheets/image_12.jpeg'
import img13 from './assests/BedSheets/image_13.jpeg'
import img14 from './assests/BedSheets/image_14.jpeg'
import img15 from './assests/BedSheets/image_15.jpeg'
import img16 from './assests/BedSheets/image_16.jpeg'
import img17 from './assests/BedSheets/image_17.jpeg'


export const BedSheetsImages = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    // img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,

]