import React from "react";
import bg from "../assests/background.jpg";

const PageHeader = ({ message }) => {
  const gradient = "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7))";
  const backgroundImage = `url(${bg})`;

  return (
    <div
      style={{
        backgroundImage: backgroundImage,
        // backgroundAttachment: "fixed",
        backgroundPosition: "center",
        backgroundSize: "cover",
         height:'230px',
        backgroundRepeat: "no-repeat",
        width: "auto",
        alignItems: "center",
        display: "flex",
      }}
      className="mdl:h-[50vw]  h-[30vw] font-regular text-white flex items-center text-[6vw] w-[100vw] mdl:text-[5.5vw] font-[700]  tracking-widest xl:px-[90px] lg:px-[80px] px-[20px]"
    >
      <div className=" max-w-[1600px]">
        <h1 className= 'md:text-[50px] text-[30px]'> {message} </h1>
      </div>
    </div>
  );
};

export default PageHeader;
