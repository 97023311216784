import React from "react";
import { useRef } from "react";
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";
const RequestForm = () => {

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_680ax59",
        "template_0shvjuf",
        form.current,
        "v1uPruTvqeXniF8L0"

      )
      .then(
        (result) => {
          console.log("Email sent successfully!");
          toast.success("Email sent successfully!");
        },
        (error) => {
          console.error("Failed to send email:", error);
          toast.error("Failed to send email. Please try again later.");
        }
      );
    e.target.reset();
  };
  return (
    <div>
      <form onSubmit={sendEmail}
        ref={form} className="xl:w-[60vw] w-[90vw] mx-auto pb-10  grid mdl:grid-cols-2 grid-cols-1 gap-5 lg:space-y-5">
        <h1 className="lg:text-[3vw] text-[5vw] text-[#E21515] text-center col-span-1 mdl:col-span-2">MAKE A REQUEST</h1>

        <input required type="text" name= 'name' placeholder="Name......" className="  col-span-1  h-[4rem] outline-none focus:border-[#E21515] border-[1px] px-5 py-2 placeholder:font-mono" />
        <input required type="email" placeholder="Email" name = 'email' className="  col-span-1  h-[4rem] outline-none focus:border-[#E21515] border-[1px] px-5 py-2 placeholder:font-mono" />
        <input required type="tel" placeholder="Phone Number.." name="phoneNumber" className="  col-span-1  h-[4rem] outline-none focus:border-[#E21515] border-[1px] px-5 py-2 placeholder:font-mono" />
        <input required type="address" placeholder="Building Location..." name='address' className="  col-span-1  h-[4rem] outline-none focus:border-[#E21515] border-[1px] px-5 py-2 placeholder:font-mono" />
        <input required type="number" placeholder="How many windows?" className="  col-span-1  h-[4rem] outline-none focus:border-[#E21515] border-[1px] px-5 py-2 placeholder:font-mono" />
        <textarea required cols="40"  rows="6" class="" aria-invalid="false" placeholder="Message" name="message" className=" outline-none focus:border-[#E21515] border-[1px] px-5 py-2 placeholder:font-mono col-span-1 mdl:col-span-2"></textarea>
        <button type="submit" className="bg-[#E21515] w-[13rem] py-3 font-regular text-white text-[16px] hover:bg-black transition-all duration-700 hover:border-orange-500 hover:border">Send</button>
      </form>
    </div>
  );
};

export default RequestForm;
