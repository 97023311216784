import React, { useState } from 'react'
import { Link, useLocation } from "react-router-dom";
import { FiClock } from "react-icons/fi"; import logo from '../assests/logo.png'
import { FaInstagram, FaFacebook, FaTwitter, FaFacebookF } from "react-icons/fa";
import { BsFillEnvelopeFill, BsTelephoneFill } from "react-icons/bs";
const Footer = () => {

  const location = useLocation();
  const [activeRoute, setActiveRoute] = useState(location.pathname);

  const handleRouteClick = (route) => {
    setActiveRoute(route);
  };

  const links = [
    {
      id: 1,
      title: "HOME",
      url: "/",
    },
    {
      id: 2,
      title: "ABOUT US",
      url: "/About-us",
    },
    // {
    //   id: 3,
    //   title: "GALLERY",
    //   url: "/Gallerry",
    // },
    // {
    //   id: 4,
    //   title: "PRODUCTS",
    //   url: "/Products",
    // },
    {
      id: 5,
      title: "SERVICES",
      url: "/Services",
    },
    {
      id: 6,
      title: "CONTACT",
      url: "/Contact-us",
    },
  ];
  return (
    <div className="bg-[#202020]">
      <div className='py-20  max-w-[1600px] mx-auto  flex lgl:flex-row flex-col lgl:space-x-17 md:justify-between space-y-12 md:px-12 px-5  '>
        <div className="mt-10">

          <img src={logo} alt="logo" className="w-[120px] aspect-square h-[80px]" />
          <p className='text-white text-[14px] font-regular leading-6 tracking-wider mt-1 max-w-sm'>
            NNAROID Global Concept is an interior decoration firm that deals
            on all kinds of interior decoration products such as Curtain/Drape,
            Window blind, Beddings, Curtain Accessories etc.
          </p>
        </div>

        <div className="">

          {/* <ul className="flex flex-col mt-5 font-regular text-[13px]  space-y-6  text-white">
            {links.map((item) => (
              <li key={item.id}>
                <Link
                  onClick={() => handleRouteClick(item.url)}
                  className={
                    activeRoute === item.url
                      ? "hover:text-orange-800 transition-all duration-700  text-orange-800 "
                      : "hover:text-orange-800 transition-all duration-700"
                  }
                  to={item.url}
                >
                  {item.title}
                </Link>
              </li>
            ))}
          </ul> */}

          <div className="flex lg:space-x-10 sml:space-x-44  space-x-14 mt-3">
            <div className="">
              <ul className="flex-col text-white  font-regular text-[14px]  flex space-y-4">
                <div className="flex items-center  flex-row  space-x-2">
                  <span className="text-2xl font-bold text-orange">.</span>
                  <li className="pt-3">
                    <Link to="/" className="hover:text-orange-500 text-[13px] font-regular trans">
                      HOME
                    </Link>
                  </li>
                </div>
                <div className="flex items-center  flex-row  space-x-2">
                  <span className="text-2xl font-bold text-orange">.</span>
                  <li className="pt-3">
                    <Link to="/Bed-sheets" className="hover:text-orange-500 text-[13px] font-regular trans">
                      BED SHEETS
                    </Link>
                  </li>
                </div>
                <div className="flex items-center space-x-2">
                  <span className=" text-2xl font-regular font-bold text-orange  ">.</span>
                  <li className="pt-3">
                    <Link to="/Curtains" className="hover:text-orange-500 text-[13px] font-regular trans">
                      CURTAIN
                    </Link>
                  </li>
                </div>

                <div className="flex items-center space-x-2">
                  <span className="  text-2xl font-regular font-bold text-orange">.</span>
                  <li className="pt-3">
                    <Link to="/Accessories" className="hover:text-orange-500 text-[14px] font-regular trans">
                      ACCESSORIES{" "}
                    </Link>
                  </li>
                </div>
              </ul>
            </div>
            <div className="">
              <ul className="flex-col text-white hover:text-orange font-regular text-[14px]  flex space-y-4">
                <div className="flex items-center  flex-row  space-x-2">
                  <span className="text-2xl font-bold text-orange">.</span>
                  <li className="pt-3">
                    <Link to="/About-us" className="hover:text-orange-500 text-[13px] font-regular font-regular trans">
                      ABOUT{" "}
                    </Link>
                  </li>
                </div>               <div className="flex items-center space-x-2">
                  <span className=" text-2xl font-bold text-orange">.</span>
                  <li className="pt-3">
                    <Link to="/Windows" className="hover:text-orange-500 text-[13px] font-regular font-regular trans">
                      {" "}
                      WINDOW BLINDS                   </Link>                 </li>
                </div>

                <div className="flex items-center space-x-2">
                  <span className="  text-2xl font-bold text-orange">.</span>
                  <li className="pt-3">
                    <Link to="/Contact-us" className="hover:text-orange-500 text-[13px] trans">
                      {" "}
                      CONTACT US
                    </Link>
                  </li>
                </div>
              </ul>
            </div>
          </div>

        </div>

        <div className="">
          <h1 className='text-white text-[16px]  font-regular font-bold'>CONTACT US</h1>


          <div className="text-white t mt-5 leading-7 tracking-wider font-titleFont  max-w-xs">
            <div className="flex space-x-4 group font-regular">             <BsFillEnvelopeFill className="text-white cursor-pointer  group-hover:text-orange-800 duration-700 transition-all group-hover:scale-[1.2] font-regular hover:text-orange trans text-[24px]" />
              <p className="cursor-pointer font-regular text-[14px]">nnaroidinteriorslimited@gmail.com ezannarod13@gmail.com</p>
            </div>

            <div className="text-white text-[13px]">
              <div className="flex group space-x-4 mt-2">
                <BsTelephoneFill className="text-white group-hover:text-orange-800 duration-700 transition-all group-hover:scale-[1.2] cursor-pointer font-regular hover:text-orange trans text-[17px]" />
                <p className="cursor-pointer text-[14px] font-regular">+2348129400909</p>
              </div>
            </div>

            <div className="flex text-white group mt-2 text-[13px] font-titleFont space-x-4">
              <FiClock className="text-white group-hover:text-orange-800 duration-700 transition-all group-hover:scale-[1.2] cursor-pointer font-bold hover:text-orange trans text-[20px]" />
              <div className="flex md:space-x-7 cursor-pointer font-regular space-x-12 xl:space-x-8">
                <p>Monday - Saturday:</p>
                <p className="font-semibold  text-[14px] font-regular">8am to 6pm</p>
              </div>
            </div>


          </div>
        </div>
      </div>
      <div className="py-8  px-12 text-white bg-black">
        <div className='max-w-[1600px] mx-auto flex flex-col space-y-2 md:flex-row md:justify-between justify-center items-center'>
          <div className="">
            <p className='text-[13px] font-regular'>Developed by Code Sensei</p>
          </div>
          <div className="flex items-center space-x-3">
            <div className="py-3 rounded-full px-3 hover:bg-orange-800 duration-700 transition-all bg-[#202020] ">
              <a href="https://www.facebook.com/Ezeshedrach.nnabuike?mibextid=ZbWKwL" rel="noopener  noreferrer" target="_blank">
                <FaFacebookF className='text-lg' />
              </a>

            </div>
            <div className="py-3 rounded-full hover:bg-orange-800 duration-700 transition-all px-3 bg-[#202020] ">
              <a href="https://www.instagram.com/nnarod__/" target="_blank" rel="noopener noreferrer" >
                <FaInstagram className='text-lg ' />
              </a>

            </div>

          </div>
          <div className="">
            <p className='text-[13px] font-regular'>© 2023 Nnarod Interiors</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
















// import React from "react";
// import { FaMapMarkerAlt } from "react-icons/fa";
// import { FiClock } from "react-icons/fi";
// import { BsFillEnvelopeFill, BsTelephoneFill } from "react-icons/bs";
// import { Link } from "react-router-dom";
// // import logo from '../assests/images/obi-eko-interiors-logo.png'

// const Footer = () => {
//   return (
//     <div className="py-32 xl:px-20 mt-9 px-5 bg-[#5c5c5c]   font-regular">
//       <div className="flex lg:justify-between lg:flex-row justify-center  flex-col">
//         <div>
//           <img src={logo} alt="logo" className="w-[140px] aspect-square h-[80px]" />

//           <p className="lg:max-w-sm font-regular mt-3 text-[14px] leading-7 tracking-wider text-white">
//             NNAROID Global Concept is an interior decoration firm that deals
//             on all kinds of interior decoration products such as Curtain/Drape,
//             Window blind, Beddings, Curtain Accessories etc.
//           </p>
//         </div>

//         <div className="flex lg:space-x-6 sml:space-x-44  space-x-14 lg:mt-0  mt-16 ">
//           <div className="">
//             <ul className="flex-col text-white  font-regular text-[14px] font-semibold flex space-y-4">
//               <div className="flex items-center  flex-row  space-x-2">
//                 <span className="text-2xl font-bold text-orange">.</span>
//                 <li className="pt-3">
//                   <Link to="/" className="hover:text-orange-500 trans">
//                     HOME
//                   </Link>
//                 </li>
//               </div>
//               <div className="flex items-center space-x-2">
//                 <span className=" text-2xl font-regular font-bold text-orange  ">.</span>
//                 <li className="pt-3">
//                   <Link to="/Curtains" className="hover:text-orange-500 trans">
//                     CURTAIN
//                   </Link>
//                 </li>
//               </div>

//               <div className="flex items-center space-x-2">
//                 <span className="  text-2xl font-regular font-bold text-orange">.</span>
//                 <li className="pt-3">
//                   <Link to="/Accessories" className="hover:text-orange-500 trans">
//                     ACCESSORIES{" "}
//                   </Link>
//                 </li>
//               </div>
//             </ul>
//           </div>
//           <div className="">
//             <ul className="flex-col text-white hover:text-orange font-regular text-[14px] font-semibold flex space-y-4">
//               <div className="flex items-center  flex-row  space-x-2">
//                 <span className="text-2xl font-bold text-orange">.</span>
//                 <li className="pt-3">
//                   <Link to="/About-us" className="hover:text-orange-500 font-regular trans">
//                     ABOUT{" "}
//                   </Link>
//                 </li>
//               </div>
//               <div className="flex items-center space-x-2">
//                 <span className=" text-2xl font-bold text-orange">.</span>
//                 <li className="pt-3">
//                   <Link to="/Windows" className="hover:text-orange-500 font-regular trans">
//                     {" "}
//                     WINDOW BLINDS
//                   </Link>
//                 </li>
//               </div>

//               <div className="flex items-center space-x-2">
//                 <span className="  text-2xl font-bold text-orange">.</span>
//                 <li className="pt-3">
//                   <Link to="/Contact-us" className="hover:text-orange-500 trans">
//                     {" "}
//                     CONTACT US
//                   </Link>
//                 </li>
//               </div>
//             </ul>
//           </div>
//         </div>

//         <div className="flex space-y-5  mt-16 lg:mt-0 flex-col">
//           <div className="flex group  space-x-4">
//             <FaMapMarkerAlt className="text-white group-hover:text-orange-500 duration-700 transition-all group-hover:scale-[1.2] cursor-pointer hover:text-orange trans text-[17px]" />
//             <p className="lg:max-w-xs cursor-pointer font-regular leading-7 tracking-wider text-[15px] text-white">
//               F-264 and F-059 Tejuosho ultra modern shopping centre Yaba, Lagos
//               State.
//             </p>
//           </div>

//           <div className="text-white text-[14px]">
//             <div className="flex group space-x-4">
//               <BsTelephoneFill className="text-white group-hover:text-orange-500 duration-700 transition-all group-hover:scale-[1.2] cursor-pointer font-regular hover:text-orange trans text-[17px]" />
//               <p className="cursor-pointer">08129400909</p>
//             </div>
//           </div>

//           <div className="text-white text-[14px] leading-7 tracking-wider font-titleFont  max-w-xs">
//             <div className="flex space-x-4 group font-regular">
//               <BsFillEnvelopeFill className="text-white cursor-pointer  group-hover:text-orange-500 duration-700 transition-all group-hover:scale-[1.2] font-regular hover:text-orange trans text-[24px]" />
//               <p className="cursor-pointer">nnaroidinteriorslimited@gmail.com ezannarod13@gmail.com</p>
//             </div>
//           </div>

//           <div className="flex text-white group text-[14px] font-titleFont space-x-4">
//             <FiClock className="text-white group-hover:text-orange-500 duration-700 transition-all group-hover:scale-[1.2] cursor-pointer font-bold hover:text-orange trans text-[20px]" />
//             <div className="flex md:space-x-7 cursor-pointer font-regular space-x-12 xl:space-x-8">
//               <p>Monday - Saturday:</p>
//               <p className="font-semibold">8am to 6pm</p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Footer;
