
import React, { useState } from 'react'
import logo from '../assests/logo.png'
import { Link, useLocation } from "react-router-dom";
import image4 from '../assests/image-4.jpg'
import { FaInstagram, FaFacebook, FaBars, FaTimes } from "react-icons/fa";
const Navbar = () => {
  const [open, setOpen] = useState(false);

  const location = useLocation();
  const [activeRoute, setActiveRoute] = useState(location.pathname);

  const handleRouteClick = (route) => {
    setActiveRoute(route);
  };

  const links = [
    {
      id: 1,
      title: "HOME",
      url: "/",
    },
    {
      id: 2,
      title: "ABOUT US",
      url: "/About-us",
    },
    {
      id: 3,
      title: "CURTAINS",
      url: "/Curtains",
    },
    {
      id: 4,
      title: "WINDOW BLINDS",
      url: "/Windows",
    },
    {
      id: 5,
      title: "ACCESSORIES",
      url: "/Accessories",
    },
    {
      id: 6,
      title: "BED SHEETS",
      url: "/Bed-sheets",
    },
    {
      id: 7,
      title: "SERVICES",
      url: "/Services",
    },
    {
      id: 8,
      title: "CONTACT",
      url: "/Contact-us",
    },
  ];

  return (
    <div>
      <main className='py-5 lgl:py-2 border bg-white lgl:px-20 md:px-8 px-5'>
        <div className='flex justify-between items-center max-w-[1600px] mx-auto'>
          <img src={logo} alt="" className={`aspect-square lgl:flex hidden w-[100px] h-[75px]`} />

          <ul className="lgl:flex hidden items-center  text-black font-semibold font-regular text-[1.4vw] lg:text-[1vw] space-x-10 ">
            {links.map((item) => (
              <li key={item.id}>
                <Link
                  onClick={() => handleRouteClick(item.url)}
                  className={
                    activeRoute === item.url
                      ? "hover:text-orange-800 transition-all duration-700  text-orange-800 "
                      : "hover:text-orange-800 transition-all duration-700"
                  }
                  to={item.url}
                >
                  {item.title}
                </Link>
              </li>
            ))}
          </ul>


        </div>

        {/* mobile */}

        <div className="">
          <div className='bg-white  flex justify-between items-center'>

            {/* <div className="">
              <img
                src={logo}
                alt=""
                className='w-[100px] h-[60px] lgl:hidden aspect-square transition duration-500'
              />
            </div> */}
           
            <div className="">
              <div className="">
                {open ? <FaTimes className="text-3xl lgl:hidden text-orange-800 " onClick={() => setOpen(!open)} /> : <FaBars className="text-3xl lgl:hidden text-orange-800 " onClick={() => setOpen(!open)} />}
              </div>
            </div>

            <div className="">
              <img src={image4} alt="logo" className='w-[200px] lgl:hidden h-[50px]' />
            </div>

            <div>
              
            </div>


          </div>


          <ul className={` lgl:hidden font-regular z-10 pt-10 absolute top-[4.5rem] left-0 text-[13px] px-7 space-y-7  w-full   bg-white backdrop-blur-sm overflow-hidden  flex flex-col  ${open ? "h-[27rem] transition-all duration-900 " : "h-[0rem] transition-all duration-900  "
            }`}>
            {links.map((item) => (
              <li key={item.id}>
                <Link
                  onClick={() => handleRouteClick(item.url)}

                  className={
                    activeRoute === item.url
                      ? "hover:text-orange-800 transition-all duration-700   text-orange-800 "
                      : "hover:text-orange-800 transition-all duration-700"
                  }
                  to={item.url}
                >
                  {item.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </main>



    </div>
  )
}

export default Navbar

























// import { useEffect, useState } from "react";
// import { Link, useLocation } from "react-router-dom";
// import { FaInstagram, FaFacebook, FaBars, FaTimes } from "react-icons/fa";
// // import logo from "../assests/images/obi-eko-interiors-logo.png";
// import logo from '../assests/logo.png'
// import Hero from "./Hero";

// const Navbar = () => {
//   const [open, setOpen] = useState(false);
//   const [scroll, setScroll] = useState(false);
//   const location = useLocation();
//   const [activeRoute, setActiveRoute] = useState(location.pathname);

//   const handleRouteClick = (route) => {
//     setActiveRoute(route);
//   };

//   const links = [
//     {
//       id: 1,
//       title: "HOME",
//       url: "/",
//     },
//     {
//       id: 2,
//       title: "ABOUT US",
//       url: "/About-us",
//     },
//     {
//       id: 3,
//       title: "CURTAINS",
//       url: "/Curtains",
//     },
//     {
//       id: 4,
//       title: "WINDOW BLINDS",
//       url: "/Windows",
//     },
//     {
//       id: 5,
//       title: "ACCESSORIES",
//       url: "/Accessories",
//     },
//     {
//       id: 6,
//       title: "CONTACT US",
//       url: "/Contact-us",
//     },
//   ];

//   useEffect(() => {
//     const handleScoll = () => {
//       if (window.scrollY > 40) {
//         setScroll(true);
//       } else {
//         setScroll(false);
//       }
//     };
//     window.addEventListener("scroll", handleScoll);
//   }, []);
//   return (
//     <div >
//       <main className='py-6 xl:px-20 hidden lg:block lg:px-14 text-slate-300 tracking-wider  relative bg-black  font-regular'>
//         <div className="flex justify-between items-center">
//           <p className="text-[15px] hover:text-orange">If you can imagine it, we will design it for you!</p>

//           <div className="flex space-x-6 items-center">
//             <div className="flex items-center space-x-4">
//               {/* <FaFacebook /> */}
//               <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/nnarod__/">
//               <FaInstagram />
//               </a>
//             </div>
//             <div className="">
//           Call Now: <a href="tel:+234-129-400-909 " >08129400909</a>
//             </div>
//           </div>
//         </div>
//       </main>
//       <header
//         className={`${scroll ? "py-4  top-0 trans" : "py-4  trans "
//           } border  lg:fixed w-full bg-white z-10 px-4 font-regular   xl:px-32 lg:px-20 md:px-5`}
//       >
//         <div className="flex justify-between  items-center">
//           <img
//             src={logo}
//             alt=""
//             className={`${scroll
//               ? "w-[100px] mdl:h-[60px] aspect-square transition duration-500"
//               : "transition duration-500 aspect-square w-[100px] h-[60px]"
//             }`}
//           />
//           {open ? <FaTimes className="text-2xl mdl:hidden text-orange-500 " onClick={() => setOpen(!open)} /> : <FaBars className="text-2xl mdl:hidden text-orange-500 " onClick={() => setOpen(!open)} />}

//           <ul className="mdl:flex hidden items-center font-regular text-[1.4vw] lg:text-[1vw] space-x-9 ">
//             {links.map((item) => (
//               <li key={item.id}>
//                 <Link
//                   onClick={() => handleRouteClick(item.url)}
//                   className={
//                     activeRoute === item.url
//                       ? "hover:text-orange-500 transition-all duration-700  text-orange-500 "
//                       : "hover:text-orange-500 transition-all duration-700"
//                   }
//                   to={item.url}
//                 >
//                   {item.title}
//                 </Link>
//               </li>
//             ))}
//           </ul>
//         </div>


//       </header>

//       {/* Mobile */}
//       <ul className={` mdl:hidden font-regular z-10 mt-4 absolute text-[14px] px-14 space-y-7  w-full rounded-lg  bg-white backdrop-blur-sm overflow-hidden  flex flex-col  ${open ? "h-[18rem] transition-all duration-900 " : "h-[0rem] transition-all duration-900  "
//         }`}>
//         {links.map((item) => (
//           <li key={item.id}>
//             <Link
//               onClick={() => handleRouteClick(item.url)}

//               className={
//                 activeRoute === item.url
//                   ? "hover:text-orange-500 transition-all duration-700   text-orange-500 "
//                   : "hover:text-orange-500 transition-all duration-700"
//               }
//               to={item.url}
//             >
//               {item.title}
//             </Link>
//           </li>
//         ))}
//       </ul>


//     </div>
//   );
// };

// export default Navbar;