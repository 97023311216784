import React, { useEffect, useState } from "react";
import Footer from "../layout/Footer";
import Navbar from "../layout/Navbar";
import PageHeader from "../components/PageHeader";
import { Fade, Slide } from "react-awesome-reveal";
import {   BedSheetsImages} from "../data4";
import ButtonLoader from "../components/loader/ButtonLoader";
import RequestForm from "../layout/RequestForm";
import WhatsAppButton from "../components/WhatsAppButton";




const Bed = () => {
  const [Bed, setBeds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const isVideo = (img) => {
    const videoExtensions = [".mp4", ".webm", ".ogg"]; // Add more video extensions if needed
    return videoExtensions.some((ext) => img.endsWith(ext));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const CurtainImages = [ ]


  useEffect(() => {
    // Simulate an API call or data loading
    setTimeout(() => {
      setBeds( BedSheetsImages);
      setIsLoading(false); // Mark loading as complete
    }, 2000); // Adjust the duration as needed
  }, []);

  return (
    <div className="relative">
      <Navbar />
      <Fade duration={3000}>
        <PageHeader message={"BED SHEETS"} />
      </Fade>


      {isLoading ? (
        <div className="my-[5rem]">
          <ButtonLoader />
        </div>
      ) : (
        <main className="grid mdl:grid-cols-3 grid-cols-1 gap-7 px-[1rem] lg:px-[8rem] py-10">
          <Fade duration={4000} triggerOnce  >
            {Bed.map((img, index) => (
              <div key={index}>
            {isVideo(img) ? (
                  <video
                    src={img}
                    controls
                    width="100%"
                    height="auto"
                    className="col-span-1 object-cover aspect-[2/2.3]"
                    // onLoad={() => handleLoad(index)}
                  />
                ) : (
                  <img
                    src={img}
                    alt=""
                    className="col-span-1 object-cover aspect-[2/2.3]"
                    // onLoad={() => handleLoad(index)}
                  />
                )}
              </div>
            ))}
          </Fade>
        </main>
      )}

      <Slide triggerOnce duration={1000}>
        <RequestForm />
      </Slide>

      <Footer />
      <WhatsAppButton style={'fixed right-0 z-[9999999999999] top-[80vh]'}/>
    </div>
  );
};

export default Bed;
